





































































































































import { Vue, Component, Watch, Ref, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import JurisdictionForm from "@/application-manager/components/JurisdictionForm.vue";
import { JurisdictionValueModel } from "@/application-manager/models/JurisdictionResponseModel";
import JurisdictionResponseModel from "@/application-manager/models/JurisdictionResponseModel";
import { MenuItemsApplicationManager } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    JurisdictionForm,
  },
})
export default class JurisdictionValuesTable extends Vue {
  @Prop({ default: false }) isEditPage!: boolean;

  @Ref("valuesForm") readonly valuesForm!: VueForm;

  isLoading = true;
  isShowDialogDelete = false;
  isValid = true;
  isDeleteSelected = false;
  selected: Array<JurisdictionValueModel> = [];
  creatableItem: JurisdictionValueModel = new JurisdictionValueModel();
  deletedItem: JurisdictionValueModel | null = null;
  localValues: Array<JurisdictionValueModel> = [];
  editableItem: JurisdictionValueModel = new JurisdictionValueModel();

  readonly MenuItemsApplicationManager = MenuItemsApplicationManager;
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly headers = [
    {
      text: this.$lang("jsonEditor.name"),
      align: "start",
      value: "name",
      width: "40%",
    },
    {
      text: this.$lang("jsonEditor.value"),
      value: "value",
      width: "50%",
    },
    {
      text: this.$lang("jsonEditor.actions"),
      value: "actions",
      align: "end",
      sortable: false,
      width: "5%",
    },
  ];

  get inputErrorMessages(): any {
    return (item?: JurisdictionValueModel): Array<string> => {
      let isNotUnique = false;

      if (item) {
        isNotUnique = this.values
          .filter((value) => value.id !== item.id)
          .some((value) => value.name === item.name);
      } else {
        isNotUnique = this.values.some(
          (value) => value.name === this.creatableItem.name
        );
      }

      if (isNotUnique) {
        return [this.$lang("validation.unique")];
      }

      return [];
    };
  }

  get titleForDialogDelete(): string {
    if (this.isDeleteSelected) {
      const deletedItems = this.selected.map((item) => item.name).join(", ");
      return this.$lang("configResponseEditor.dialogDeleteTitle", deletedItems);
    }

    return this.$lang(
      "configResponseEditor.dialogDeleteTitle",
      this.deletedItem?.name as string
    );
  }

  get jurisdictionId(): number {
    return this.isEditPage
      ? Number(this.$route.params?.jurisdictionId)
      : Number(this.$route.query?.jurisdictionId);
  }

  get values(): Array<JurisdictionValueModel> {
    return this.$store.state.jurisdictionStore.jurisdictionValues;
  }

  get jurisdictionName(): JurisdictionResponseModel {
    return this.$store.state.jurisdictionStore.jurisdiction?.name;
  }

  @Watch("values", { immediate: true, deep: true })
  private watchValues() {
    this.localValues = cloneDeep(this.values);
  }

  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch("loadJurisdiction", this.jurisdictionId),
      this.$store.dispatch("getJurisdictionValues", this.jurisdictionId),
    ]);
    this.isLoading = false;
  }

  destroyed() {
    this.$store.commit("clearJurisdictionValues");
  }

  handleDeleteSelected() {
    this.isDeleteSelected = true;
    this.isShowDialogDelete = true;
  }

  resetEditItemData() {
    this.creatableItem = new JurisdictionValueModel();
    this.valuesForm.resetValidation();
  }

  async saveForm() {
    await this.$store.dispatch("addJurisdictionValue", {
      payload: {
        name: this.creatableItem.name,
        value: this.creatableItem.value,
      },
      profileId: this.jurisdictionId,
    });

    this.resetEditItemData();
  }

  handleOpenEditPopup(item: JurisdictionValueModel) {
    this.editableItem = cloneDeep(item);
  }

  async handleUpdateItem(item: JurisdictionValueModel) {
    if (
      this.editableItem.name &&
      this.editableItem.value &&
      !this.inputErrorMessages(this.editableItem).length
    ) {
      item.name = this.editableItem.name;
      item.value = this.editableItem.value;

      const oldValueName = this.values.find(
        (value: JurisdictionValueModel) => value.id === item.id
      )?.name;

      await this.$store.dispatch("updateJurisdictionValue", {
        valueName: oldValueName,
        payload: { name: item.name, value: item.value },
        profileId: this.jurisdictionId,
      });
    }
  }

  async deleteItemConfirm() {
    if (this.selected.length) {
      const promises: any = [];

      this.selected.forEach((item: JurisdictionValueModel) => {
        promises.push(
          this.$store.dispatch("deleteJurisdictionValue", {
            valueName: item.name,
            profileId: this.jurisdictionId,
          })
        );
      });

      await Promise.all(promises);
      this.selected = [];
    } else {
      await this.$store.dispatch("deleteJurisdictionValue", {
        valueName: this.deletedItem?.name,
        profileId: this.jurisdictionId,
      });
    }

    this.closeDelete();
  }

  handleItemDeleting(item: JurisdictionValueModel) {
    this.deletedItem = item;
    this.isShowDialogDelete = true;
  }

  closeDelete() {
    this.isShowDialogDelete = false;
  }

  resetValidation() {
    this.valuesForm.resetValidation();
  }
}
