import JurisdictionResponseModel, {
  JurisdictionValueModel,
} from "./JurisdictionResponseModel";

export default class JurisdictionRequestModel {
  constructor(
    public id?: number,
    public name = "",
    public description = "",
    public values: Array<JurisdictionValueModel> = []
  ) {}

  static ofSource(
    model: JurisdictionRequestModel | JurisdictionResponseModel
  ): JurisdictionRequestModel {
    return new JurisdictionRequestModel(
      model.id,
      model.name,
      model.description,
      model.values
    );
  }
}
